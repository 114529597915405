import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ContingencyFlagsGQL } from '@generated/graphql';
import { AsyncSubject } from 'rxjs';
import { GraphQLService } from '../graphql/graphql.service';

export type ContingencyFlags = {
  isContingency: boolean;
  isIPL: boolean;
  alternateTradePaths: AlternateTradePaths;
};

export type AlternateTradePaths = {
  isEtfsAlternateTradePath: boolean;
  isMutualFundsAlternateTradePath: boolean;
};
@Injectable({
  providedIn: 'root',
})
export class ContingencyService {
  private readonly contingencyFlags: AsyncSubject<ContingencyFlags>;
  hasBeenFetched = false;

  constructor(
    private readonly gqlService: GraphQLService,
    private readonly contingencyFlagsGQL: ContingencyFlagsGQL,
  ) {
    this.contingencyFlags = new AsyncSubject<ContingencyFlags>();
    this.contingencyFlags.next({
      isContingency: false,
      isIPL: false,
      alternateTradePaths: {
        isEtfsAlternateTradePath: false,
        isMutualFundsAlternateTradePath: false,
      },
    });
  }

  private fetchContingencyData(): void {
    this.gqlService.query(this.contingencyFlagsGQL.document).subscribe(({ data }) => {
      let contingencyFlags: ContingencyFlags = {
        isContingency: false,
        isIPL: false,
        alternateTradePaths: {
          isEtfsAlternateTradePath: false,
          isMutualFundsAlternateTradePath: false,
        },
      };
      if (data?.contingencyFlags) {
        contingencyFlags = {
          isContingency: data.contingencyFlags.isContingency,
          isIPL: data.contingencyFlags.isIpl,
          alternateTradePaths: {
            isEtfsAlternateTradePath: data.contingencyFlags.isEtfsAtp,
            isMutualFundsAlternateTradePath: data.contingencyFlags.isMutualFundsAtp,
          },
        };
      }
      this.contingencyFlags.next(contingencyFlags);
    });
  }

  public getContingencyFlags(
    snapshot: ActivatedRouteSnapshot,
  ): Promise<ContingencyFlags | undefined> {
    if (snapshot.data?.isLimited && !this.hasBeenFetched) {
      this.fetchContingencyData();
      this.hasBeenFetched = true;
    }
    this.contingencyFlags.complete();
    return this.contingencyFlags.toPromise().then((data) => data);
  }
}
