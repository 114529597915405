import { Injectable } from '@angular/core';

export enum ProductType {
  SUNGARD,
}

@Injectable({
  providedIn: 'root',
})
export class ProductTypeInterpreterService {
  private readonly toProductTypes = {
    Sungard: ProductType.SUNGARD,
  } as const;

  constructor() {}

  private toProductType(productType: string): ProductType | undefined {
    return this.toProductTypes[productType];
  }

  isPersonalTrust(productType: string): boolean {
    return this.toProductType(productType) === ProductType.SUNGARD;
  }
}
