import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinksComponent } from './links.component';
import { LinkModule } from '@vg-constellation/angular-14/link';
import { DirectivesModule } from '@app/directives/directives.module';
import { GatekeeperModule } from '@vanguard/secure-site-components-lib';

@NgModule({
  declarations: [LinksComponent],
  imports: [CommonModule, LinkModule, DirectivesModule, GatekeeperModule],
  exports: [LinksComponent],
})
export class LinksModule {}
