<ss-lib-page-layout>
  <greetings-widget headline [currentBalance]="greetingBalance" [valueAsOfDate]="greetingAsOfDate"></greetings-widget>
  <div inner>
    <div class="row">
      <div class="col">
        <div class="d-print-none">
          <ss-lib-secondary-nav widgetActiveItem="AccountsTab" [displayPrintLink]="true"></ss-lib-secondary-nav>
        </div>
        <div class="d-none d-print-block mb-3 mt-3 balancesTab">
          <p class="balancesTabText">Balances</p>
        </div>
      </div>
    </div>
    <!-- Marketing A-SPOT container -->
    <ng-container *gatekeeper="'interact'">
      <div id="target-aspot-offer" class="target-web-offer">
      </div>
    </ng-container>
    <div class="row">
      <div class="col mb-4">
        <app-customize-view></app-customize-view>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col">
        <app-client-alerts></app-client-alerts>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-balances></app-balances>
      </div>
    </div>
    <div class="row d-print-none">
      <div class="col">
        <app-links></app-links>
      </div>
    </div>
    <div *gatekeeper="'yourAdvisor'" class="row d-print-none advisor-section__wrapper">
      <hr />
      <div class="col advisor-section__card">
        <uhnw-dpoc-contact-us class="d-{{hideDPOCCard ? 'none' : 'block'}}" is-shown-photo=true is-shown-working-hours=true is-shown-send-message=true is-shown-schedule-appointment=true is-shown-phone-number=true is-shown-expand-icon=true is-shown-account-help=true show-only-for-s-f=true is-shown-schedule-in-new-window=false is-shown-banner-message=false app-code="XS0" header-title="Team" info-position="icon" content-align="left"></uhnw-dpoc-contact-us>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-disclaimers
          [hasCashManagementAccount]="hasCashManagementAccount"
          [hasPrivateEquityAccount]="hasPrivateEquityAccount"
          [hasPersonalTrustAccount]="hasPersonalTrustAccount">
        </app-disclaimers>
      </div>
    </div>
  </div>
</ss-lib-page-layout>
