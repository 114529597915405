<ng-container content [bundleName]="'disclosuresContent'" #content="bundle">
  <div class="c11n-text-sm disclaimers-section">
    <p class="mt-3" data-testid="brok-disclosure">
      {{ content.brokerageDisclosure }}
    </p>
    <p *ngIf="hasPersonalTrustAccount" class="c11n-text-sm mt-3" data-testid="personalTrust-disclosure-partOne">
      {{ content.personalTrustDisclosurePartOne }}
    </p>
    <ss-lib-disclosure-text
      data-testid="brok-legal-disclosure"
      [numberOfAsterisks]="1"
      [variant]="DisclosureVariants.BROKERAGE_LEGAL_DISCLOSURE"></ss-lib-disclosure-text>
    <ss-lib-disclosure-text
      data-testid="cash-plus-disclosure"
      *ngIf="hasCashManagementAccount"
      [numberOfAsterisks]="2"
      [variant]="cashPlusDisclosureVariant"></ss-lib-disclosure-text>
    <ss-lib-disclosure-text
      data-testid="private-equity-disclosure"
      *ngIf="hasPrivateEquityAccount"
      [numberOfAsterisks]="0"
      [variant]="DisclosureVariants.PRIVATE_EQUITY_DISCLOSURE"></ss-lib-disclosure-text>
  </div>
</ng-container>
