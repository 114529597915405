<div style="display: none">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="1000" height="1000">
    <symbol id="warning-small" viewBox="0 0 14 14">
      <path
        d="M13.842,11.829l-5.5-11a1.5,1.5,0,0,0-2.684,0l-5.5,11A1.5,1.5,0,0,0,1.5,14h11a1.5,1.5,0,0,0,1.342-2.171ZM6.25,5a.75.75,0,0,1,1.5,0V8a.75.75,0,0,1-1.5,0ZM7,12a1,1,0,1,1,1-1A1,1,0,0,1,7,12Z"
      ></path>
    </symbol>
    <symbol id="remove-small" viewBox="0 0 14 14">
      <path
        d="M8.591,7.177a.25.25,0,0,1,0-.354l4.616-4.616A1,1,0,1,0,11.793.793L7.177,5.409a.25.25,0,0,1-.354,0L2.207.793A1,1,0,0,0,.793,2.207L5.409,6.823a.25.25,0,0,1,0,.354L.793,11.793a1,1,0,0,0,1.414,1.414L6.823,8.591a.25.25,0,0,1,.354,0l4.616,4.616a1,1,0,0,0,1.414-1.414Z"
      ></path>
    </symbol>
  </svg>
</div>

<ng-container
  *ngIf="
    alertData?.returnByPostOffice ||
    alertData?.recentlyAddedBank ||
    alertData?.hasInstitutionalError
  "
  content
  [bundleName]="'clientAlerts'"
  #content="bundle"
>
  <c11n-banner [headingText]="content.alertHeader" [variant]="'warning'">
    <ng-container *ngIf="alertData.returnByPostOffice">
      {{ content.returnByPostOffice }}
      <a
        c11n-link
        variant="secondary-reinforced"
        size="medium"
        href="{{ content.returnByPostOfficeUrl }}"
        >{{ content.returnByPostOfficeLabel }}</a
      >
      {{ content.returnByPostOffice2 }}
      <br />
    </ng-container>
    <ng-container *ngIf="alertData.recentlyAddedBank">
      {{ content.recentlyAddedBank }}
      <a
        c11n-link
        variant="secondary-reinforced"
        size="medium"
        href="{{ content.recentlyAddedBankUrl }}"
        >{{ content.recentlyAddedBankLabel }}</a
      >{{ content.period }}
      <br />
    </ng-container>
    <ng-container *ngIf="alertData.hasInstitutionalError">
      {{ content.institutionalError }}
      <a
        c11n-link
        variant="secondary-reinforced"
        size="medium"
        href="{{ content.institutionalErrorUrl }}"
        >{{ content.institutionalErrorLabel }}</a
      >{{ content.period }}
    </ng-container>
  </c11n-banner>
</ng-container>
