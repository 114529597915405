import { Component, OnDestroy, OnInit } from '@angular/core';
import { GraphQLService } from '@app/services/graphql/graphql.service';
import { ClientInfoGQL, ClientInfoQuery } from '@generated/graphql';
import { Subscription } from 'rxjs';
import { GatekeeperService } from '@vanguard/secure-site-components-lib';
import { content as rootContent } from '@content/content';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent implements OnInit, OnDestroy {
  isCrossoverClient: boolean;
  gqlSubscription: Subscription;
  useLegacyWatchList: boolean;
  content = rootContent.linksContent;

  constructor(
    private clientInfoGQL: ClientInfoGQL,
    private gqlService: GraphQLService,
    private gateKeeperService: GatekeeperService,
  ) {}

  ngOnInit(): void {
    this.getCrossoverFlagData();
    this.gateKeeperService.getStatus('useLegacyWatchListUrl').then((status) => {
      this.useLegacyWatchList = status || false;
    });
  }

  getCrossoverFlagData(): void {
    this.gqlSubscription = this.gqlService
      .query<ClientInfoQuery>(this.clientInfoGQL.document)
      .subscribe(({ data }) => {
        if (data) {
          this.isCrossoverClient = data.client.institutional;
        }
      });
  }

  ngOnDestroy(): void {
    this.gqlSubscription.unsubscribe();
  }
}
