import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { LoggerCode } from '@models/logger';
import { LoggerService } from '@vanguard/secure-site-components-lib';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  sPoid?: string;
  private routerSubscription: Subscription;

  constructor(private meta: Meta, private router:Router, private logger: LoggerService) {}

  ngOnInit(): void {
    this.sPoid = this.meta.getTag('name="DCSext.sPoid"')?.content;
    this.routerSubscription = this.router.events.pipe(
      filter((e): e is NavigationEnd =>  e instanceof NavigationEnd),
      map((e:NavigationEnd) => {
         this.logger.info({
          eventType: 'PageLoadSuccess',
          logCode: LoggerCode.PAGE_LOAD,
          message: e.url
        })
      })
      ).subscribe();
  }

  ngOnDestroy(): void {
      this.routerSubscription.unsubscribe();
  }
}
