import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BalancesComponent } from './balances.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { LinkModule } from '@vg-constellation/angular-14/link';
import {
  TableFooterModule,
  DataTableModule,
  SSLibPipesModule,
  ErrorMessageModule,
} from '@vanguard/secure-site-components-lib';
import { SpinnerModule } from '@vg-constellation/angular-14/spinner';
import { AccordionModule } from '@vg-constellation/angular-14/accordion';
import { IconModule } from '@vg-constellation/angular-14/icon';
import { PrivateEquityComponent } from '@app/components/balances/private-equity/private-equity-component';
import { TransactComponent } from '../transact/transact.component';
@NgModule({
  declarations: [BalancesComponent, PrivateEquityComponent],
  imports: [
    CommonModule,
    DirectivesModule,
    DataTableModule,
    LinkModule,
    SpinnerModule,
    AccordionModule,
    TableFooterModule,
    IconModule,
    TransactComponent,
    ErrorMessageModule,
    SSLibPipesModule,
  ],
  exports: [BalancesComponent],
})
export class BalancesModule {}
