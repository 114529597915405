import { Component, OnInit } from '@angular/core';
import { ClientAlertsDataGQL } from '@generated/graphql';
import { GraphQLService } from '@app/services/graphql/graphql.service';
import { AlertModel } from '../../../models/client-alerts.model';
import { BalancesService } from '@app/services/balances/balances.service';

@Component({
  selector: 'app-client-alerts',
  templateUrl: './client-alerts.component.html',
  styleUrls: ['./client-alerts.component.scss'],
})
export class ClientAlertsComponent implements OnInit {
  alertData: AlertModel;

  constructor(
    private clientAlertsGQL: ClientAlertsDataGQL,
    private gqlService: GraphQLService,
    private balancesService: BalancesService,
  ) {}

  ngOnInit(): void {
    this.getClientAlertsComponentData();
  }

  getClientAlertsComponentData(): void {
    const promise = Promise.all([
      this.balancesService.getBalancesData(),
      this.gqlService.query(this.clientAlertsGQL.document).toPromise(),
    ]);

    promise.then(([accountInfo, clientAlertsQueryResult]) => {
      const clientAlerts = clientAlertsQueryResult?.data?.clientAlerts;
      const accountErrors = accountInfo?.accountErrors;

      this.alertData = {
        hasInstitutionalError: accountErrors?.hasInstitutionalError || false,
        recentlyAddedBank: clientAlerts?.recentlyAddedBank || false,
        returnByPostOffice: clientAlerts?.returnByPostOffice || false,
      };
    });
  }
}
