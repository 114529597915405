import { NgModule } from '@angular/core';
import { ContentDirective } from '@app/directives/content/content.directive';

const DIRECTIVES = [ContentDirective];

@NgModule({
  declarations: [DIRECTIVES],
  providers: [],
  exports: [DIRECTIVES],
})
export class DirectivesModule {}
