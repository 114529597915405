import { NgModule } from '@angular/core';

// Service Providers
import { GraphQLService } from './graphql/graphql.service';
import { GatekeeperModule, WindowRef } from '@vanguard/secure-site-components-lib';
import { BalancesService } from '@app/services/balances/balances.service';
import { ApolloModule } from 'apollo-angular';
const SERVICES = [WindowRef, GraphQLService, BalancesService];

@NgModule({
  imports: [GatekeeperModule, ApolloModule],
  providers: [SERVICES],
})
export class ServicesModule {}
