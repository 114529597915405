import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisclaimersComponent } from './disclaimers.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { LinkModule } from '@vg-constellation/angular-14/link';
import { DisclosureTextModule } from '@vanguard/secure-site-components-lib';

@NgModule({
  declarations: [DisclaimersComponent],
  imports: [CommonModule, DirectivesModule, LinkModule, DisclosureTextModule],
  exports: [DisclaimersComponent],
})
export class DisclaimersModule {}
