import { Component, OnInit } from '@angular/core';
import { GatekeeperService } from '@vanguard/secure-site-components-lib';
import { content } from '@content/content';
import { LinkComponent } from '@vg-constellation/angular-14/link';

@Component({
  standalone: true,
  selector: 'app-customize-view',
  templateUrl: './customize-view.component.html',
  styleUrls: ['./customize-view.component.scss'],
  imports: [LinkComponent],
})
export class CustomizeViewComponent implements OnInit {
  public customViewContent;
  public customViewUrl: string;
  constructor(private readonly gatekeeperService: GatekeeperService) {
    this.customViewContent = content.customViewContent;
  }

  ngOnInit(): void {
    this.gatekeeperService.getStatus('enableLegacyCustomViewUI').then((status) => {
      this.customViewUrl = status ? this.customViewContent.legacyUrl : this.customViewContent.url;
    });
  }
}
