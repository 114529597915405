<div class="customize-view">
  <p>
    <span [attr.data-testid]="'customize-view1'"> {{ customViewContent.customView1 }} </span>
    <a c11n-link [attr.data-testid]="'customize-view-link'"
       href= "{{ customViewUrl }}"
       variant="secondary-reinforced">
      <span class="c11n-link__text">{{ customViewContent.customViewLinkLabel }}</span>
    </a>
    <span [attr.data-testid]="'customize-view2'"> {{ customViewContent.customView2 }} </span>
  </p>
</div>
