import { Component, Input, OnInit } from '@angular/core';
import { DisclosureVariants } from '@vanguard/secure-site-components-lib';

@Component({
  selector: 'app-disclaimers',
  templateUrl: './disclaimers.component.html',
  styleUrls: ['./disclaimers.component.scss'],
})
export class DisclaimersComponent implements OnInit {
  @Input() hasCashManagementAccount: boolean;
  @Input() hasPrivateEquityAccount: boolean;
  @Input() hasPersonalTrustAccount: boolean;

  DisclosureVariants: typeof DisclosureVariants = DisclosureVariants;
  cashPlusDisclosureVariant: typeof DisclosureVariants[keyof typeof DisclosureVariants];

  constructor() {}

  ngOnInit(): void {
      this.cashPlusDisclosureVariant = DisclosureVariants.CASH_PLUS_DISCLOSURE_V3;
  }
}
