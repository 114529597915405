import { BrowserModule, Meta } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './components/base/base.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '@environments/environment';
import { ServicesModule } from '@app/services/services.module';
import { CookieService } from 'ngx-cookie-service';
import {
  WindowRef,
  GatekeeperModule,
  GATEKEEPER_ENDPOINT,
  LoggerModule,
  LOGGER_CONFIG,
  ENVIRONMENT_CONFIG as COMPONENT_LIB_ENVIRONMENT_CONFIG,
  Environment as ComponentLibEnvironment,
  AdobeLaunchModule,
} from '@vanguard/secure-site-components-lib';
import {
  CompositePropagatorModule,
  OpenTelemetryInterceptorModule,
  OtelColExporterModule,
} from '@jufab/opentelemetry-angular-interceptor';
import { buildOtelConfig } from './open-telemetry';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    HomeModule,
    AppRoutingModule,
    ServicesModule,
    GatekeeperModule,
    LoggerModule,
    AdobeLaunchModule,
    OpenTelemetryInterceptorModule.forRoot(buildOtelConfig()),
    OtelColExporterModule,
    CompositePropagatorModule,
  ],
  providers: [
    CookieService,
    {
      provide: GATEKEEPER_ENDPOINT,
      useValue: environment.GATEKEEPER_URI,
    },
    {
      provide: LOGGER_CONFIG,
      useValue: { endpoint: environment.LOGGER_URI, app: 'balances.frontend' },
    },
    {
      provide: COMPONENT_LIB_ENVIRONMENT_CONFIG,
      //@ts-ignore
      useValue: {
        isProd: environment.production,
        isPerf: environment.performance,
        isInternal: environment.internal,
        appName: 'balances.frontend',
      } as ComponentLibEnvironment,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: function (
        http: HttpClient,
        meta: Meta,
        windowRef: WindowRef,
      ): () => Promise<any> {
        const $window: Window = windowRef.nativeWindow;
        return async (): Promise<any> => {
          await http
            .get(`${environment.CONSUMER_API}adobe`, { withCredentials: true })
            .toPromise()
            .then((data: any) => {
              const spoid = data.sPOID;
              meta.updateTag({ name: 'DCSext.poid', content: data.poid });
              meta.updateTag({ name: 'WT.seg_6', content: data.crewFlag });
              $window.spoid = spoid; // Used for Medallia
              windowRef.nativeWindow.randomNum = Math.floor(Math.random() * 4) + 1; // Used for Medallia
              // 'unhide' app <body> now that page is sure to be displayed.
              $window.document.body.hidden = false;
            })
            .catch((ignore) => {
              $window.document.location.href = environment.LOGON_URL;
              // Ensure that app initialization is aborted since we're going to the (external) logon page.
              return Promise.reject();
            });
        };
      },
      deps: [HttpClient, Meta, WindowRef],
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
