import { OpenTelemetryConfig } from '@jufab/opentelemetry-angular-interceptor';
import { DiagLogLevel } from '@opentelemetry/api';
import { environment } from './../environments/environment';

const exportSpansToConsole = ['development', 'local'].includes(environment.deployment);

export const buildOtelConfig = (): OpenTelemetryConfig => ({
  commonConfig: {
    console: exportSpansToConsole,
    production: environment.production,
    logBody: false,
    serviceName: 'xs0',
    probabilitySampler: '1',
    logLevel: DiagLogLevel.NONE,
  },
  batchSpanProcessorConfig: {
    maxQueueSize: '10240',
    maxExportBatchSize: '5120',
    scheduledDelayMillis: '3000',
    exportTimeoutMillis: '30000',
  },
  otelcolConfig: {
    url: `https://${environment.otelCollectorDomain}:443/v1/traces`,
  },
});
