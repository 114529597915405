import { Account } from '@generated/graphql';

export const getManagedAccounts = (a: Account): boolean => {
  return a.grouping === 'MANAGED';
};

export const getSelfManagedAccounts = (a: Account): boolean => {
  return a.grouping === 'SELF_MANAGED';
};

export const getOutsideAccounts = (a: Account): boolean => {
  return a.grouping === 'OUTSIDE_INVESTMENT';
};
