import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerModule } from '@vg-constellation/angular-14/banner';
import { ClientAlertsComponent } from './client-alerts.component';
import { DirectivesModule } from '@app/directives/directives.module';
import { LinkModule } from '@vg-constellation/angular-14/link';

@NgModule({
  declarations: [ClientAlertsComponent],
  imports: [CommonModule, DirectivesModule, BannerModule, LinkModule],
  exports: [ClientAlertsComponent],
})
export class ClientAlertsModule {}
