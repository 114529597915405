import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BalancesService } from '@app/services/balances/balances.service';
import {
  Account,
  AccountsInfo,
  AssociatedAccount,
  AssociatedAccountProductType,
  Maybe,
  PrivateEquity,
  PrivateEquityInfo,
  RegisteredRoleFeatures,
  Totals,
} from '@generated/graphql';
import { AccountRow, PrivateEquityAccount, PrivateEquityAccountRow } from '@models/data-table';
import {
  getManagedAccounts,
  getOutsideAccounts,
  getSelfManagedAccounts,
} from './utils/balances-mapping';
import { ProductTypeInterpreterService } from '@app/services/product-type-interpreter/product-type-interpreter-service';
import { content } from '@content/content';
import { AdobeLaunchService, UrlUtils } from '@vanguard/secure-site-components-lib';
import { DOMAINS } from '@content/domains.test.external';
import { Router } from '@angular/router';

const productTypeToAssociatedAccountLinkedToContent: Record<AssociatedAccountProductType, string> =
  {
    [AssociatedAccountProductType.Brokerage]: content.linkedToLabels.linkedVBA,
    [AssociatedAccountProductType.Sungard]: content.linkedToLabels.linkedSungard,
  };

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
})
export class BalancesComponent implements OnInit {
  managedAccounts: Account[];
  selfManagedAccounts: Account[];
  outsideInvestments: Account[];
  totals: Totals;
  privateEquityAccounts: PrivateEquityAccount[] | undefined;
  isLoading: boolean;
  privateEquityRows: PrivateEquityAccountRow[] | undefined;
  managedAccountsRows: AccountRow[];
  selfManagedAccountRows: AccountRow[];
  tableFooterLabel: string;
  tableFooterValue: number;
  outsideInvestmentsLabel: string;
  outsideInvestmentsUrl: string;
  outsideInvestmentsValue: number;
  isError: boolean = false;
  currentURL: string;

  @ViewChild('content') readonly content;
  @ViewChild('contentElement') elRef: ElementRef;

  constructor(
    private balancesService: BalancesService,
    private adobeLaunchService: AdobeLaunchService,
    private productTypeInterpreterService: ProductTypeInterpreterService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.initData();
  }

  initData(): void {
    this.isLoading = true;
    this.loadAccountData().then(() => {
      this.createManagedAccountsTableData();
      this.createSelfManagedAccountsTableData();
      this.createPrivateEquityAccountTableData();
      this.setTableFooterText();
      this.isLoading = false;
    });
    this.currentURL = DOMAINS.BALANCES + this.router.url;
  }

  private async loadAccountData(): Promise<void> {
    const accountsInfo: AccountsInfo | undefined = await this.balancesService.getBalancesData();
    const accounts = accountsInfo?.accounts || [];
    const privateEquityInfo: Maybe<PrivateEquityInfo> | undefined = accountsInfo?.privateEquityInfo;
    const totals: Totals = accountsInfo?.totals || {
      managed: 0,
      outsideInvestments: 0,
      privateEquityTotalBalance: undefined,
      selfManaged: 0,
      totalBalance: 0,
      totalBalanceAsOfDate: undefined,
      vanguardAssets: 0,
    };

    if (!accounts.length) {
      this.isError = true;
    }
    this.managedAccounts = accounts.filter(getManagedAccounts);
    this.selfManagedAccounts = accounts.filter(getSelfManagedAccounts);
    this.outsideInvestments = accounts.filter(getOutsideAccounts);
    this.totals = totals;
    this.privateEquityAccounts = privateEquityInfo?.privateEquityAccounts.map(
      this.createPrivateEquityAccounts,
    );
  }

  private setTableFooterText(): void {
    this.tableFooterValue = this.totals.vanguardAssets;
    this.tableFooterLabel = this.content.totalVgAssetsLabel;
    this.outsideInvestmentsLabel = this.content.outsideInvestmentsLabel;
    this.outsideInvestmentsUrl = UrlUtils.addAccountIds(content.balancesTable.holdingsSummaryUrl, [
      'outside-investments',
    ]);
    this.outsideInvestmentsValue = this.totals.outsideInvestments;
  }

  private buildAccountLabel(account: Account): string {
    const labelName = this.buildAccountLabelName(account);
    const labelAsterisks = this.buildAccountLabelAsterisks(account);
    const labelSuffix = this.buildAccountLabelSuffix(account);
    return labelName ? labelName + labelAsterisks + labelSuffix : '';
  }

  private buildAccountLabelName(account: Account): string {
    return account.name.trim();
  }

  private buildAccountLabelAsterisks(account: Account): string {
    switch (true) {
      case account.isCashManagement:
        return '**';
      case account.isBrokerage:
        return '*';
      default:
        return '';
    }
  }

  private buildAccountLabelSuffix(account: Account): string {
    if (this.productTypeInterpreterService.isPersonalTrust(account.productType)) {
      return this.buildPersonalTrustAccountLabelSuffix(
        account.accountFlags?.clientRegisteredRoleFeatures,
      );
    }
    return '';
  }

  private buildPersonalTrustAccountLabelSuffix(
    registeredRoleFeatures: RegisteredRoleFeatures | undefined,
  ): string {
    if (registeredRoleFeatures?.isPersonalTrustCoTrustee) {
      return this.content.isCoTrusteeLabel;
    } else if (registeredRoleFeatures?.isPersonalTrustBeneficiary) {
      return this.content.isBeneficiaryLabel;
    }
    return '';
  }

  private getPrivateEquityAssociatedAccountLabel(
    associatedAccount: AssociatedAccount | undefined,
  ): string {
    return associatedAccount?.productType && associatedAccount?.vendorAccountNumber
      ? productTypeToAssociatedAccountLinkedToContent[associatedAccount.productType] +
          associatedAccount.vendorAccountNumber
      : '';
  }

  // eslint-disable-next-line complexity
  private createPrivateEquityAccounts(privateEquityData: PrivateEquity): PrivateEquityAccount {
    return {
      name: privateEquityData.accountName || '',
      balance: privateEquityData.totalBalance || 0,
      associatedAccount: privateEquityData.associatedAccount || undefined,
    };
  }

  private createPrivateEquityAccountTableData(): void {
    this.privateEquityRows = this.privateEquityAccounts?.map(
      (privateEquityAccount): PrivateEquityAccountRow => {
        return {
          ...privateEquityAccount,
          url: UrlUtils.addAccountIds(content.balancesTable.holdingsSummaryUrl, ['private-equity']),
          associatedAccountLabel: this.getPrivateEquityAssociatedAccountLabel(
            privateEquityAccount?.associatedAccount,
          ),
        };
      },
    );
  }
  private createManagedAccountsTableData(): void {
    this.managedAccountsRows = this.managedAccounts.map((account: Account) => ({
      ...account,
      url: UrlUtils.addAccountIds(content.balancesTable.holdingsSummaryUrl, [account.id]),
      accountLabel: this.buildAccountLabel(account),
    }));
  }

  private createSelfManagedAccountsTableData(): void {
    this.selfManagedAccountRows = this.selfManagedAccounts.map((account: Account) => ({
      ...account,
      url: UrlUtils.addAccountIds(content.balancesTable.holdingsSummaryUrl, [account.id]),
      accountLabel: this.buildAccountLabel(account),
    }));
  }

  trackCollapseAdobe(profileType): void {
    this.adobeLaunchService.trackEvent({
      location: this.currentURL,
      ctaName: profileType,
      ctaType: 'Accordion',
    });
  }
}
