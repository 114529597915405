<div style="display: none">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000" width="1000" height="1000">
    <symbol id="accordion-closed-small" viewBox="0 0 14 14">
      <use
        xlink:href="#accordion-open-small"
        style="transform: rotate(180deg); transform-origin: center"
      ></use>
    </symbol>
    <symbol id="accordion-open-small" viewBox="0 0 14 14">
      <g transform="matrix(1.4,0,0,1.4,0,0)">
        <path
          d="M9.794,6.607,5.884,2.49A1.255,1.255,0,0,0,4.1,2.5l-3.9,4.1A.75.75,0,1,0,1.294,7.641L4.819,3.928a.249.249,0,0,1,.362,0L8.706,7.641A.75.75,0,1,0,9.794,6.607Z"
          d="M5,0a5,5,0,1,0,5,5A5.006,5.006,0,0,0,5,0ZM7.53,6.28a.748.748,0,0,1-1.06,0L5.177,4.987a.252.252,0,0,0-.354,0L3.53,6.28A.75.75,0,0,1,2.47,5.22l2-2a.749.749,0,0,1,1.06,0l2,2A.749.749,0,0,1,7.53,6.28Z"
        ></path>
      </g>
    </symbol>
    <symbol id="help-small" viewBox="0 0 14 14">
      <path
        d="M7,0a7,7,0,1,0,7,7A7.008,7.008,0,0,0,7,0ZM7,11.751a1,1,0,1,1,1-1A1,1,0,0,1,7,11.751Zm.917-3.908a.25.25,0,0,0-.167.236V8.25a.75.75,0,0,1-1.5,0v-1A.75.75,0,0,1,7,6.5,1.25,1.25,0,1,0,5.75,5.25a.75.75,0,0,1-1.5,0A2.75,2.75,0,1,1,7.917,7.843Z"
      ></path>
    </symbol>
    <symbol id="link-right-small" viewBox="0 0 14 14">
      <g transform="matrix(0.5833333333333334,0,0,0.5833333333333334,0,0)">
        <path
          d="M23.987,12a2.411,2.411,0,0,0-.814-1.8L11.994.361a1.44,1.44,0,0,0-1.9,2.162l8.637,7.6a.25.25,0,0,1-.165.437H1.452a1.44,1.44,0,0,0,0,2.88H18.563a.251.251,0,0,1,.165.438l-8.637,7.6a1.44,1.44,0,1,0,1.9,2.161L23.172,13.8A2.409,2.409,0,0,0,23.987,12Z"
        ></path>
      </g>
    </symbol>
  </svg>
</div>
<ng-container content [bundleName]="'balancesTable'" #content="bundle">
  <div *ngIf="isLoading">
    <span class="loading-spinner">
      <c11n-spinner colorMode="on-light" [spinnerStatus]="'Loading accounts data'"></c11n-spinner>
    </span>
  </div>
  <div *ngIf="!isLoading && isError">
    <ss-lib-error-message></ss-lib-error-message>
  </div>
  <div *ngIf="!isLoading">
    <c11n-accordion
      *ngIf="managedAccounts.length"
      [isExpanded]="true"
      [size]="'large'"
      labelText="{{ content.managedLabel }}"
      summaryText="{{ totals.managed | currency: 'USD' }}"
      [iconPosition]="'leading'"
      [labelWeight]="'heavy'"
      (stateChange)="trackCollapseAdobe('balances_managedAccounts_accordion')"
    >
      <span accordion-body>
        <div *ngIf="managedAccounts.length" class="accounts__wrapper col-12">
          <div *ngFor="let account of managedAccountsRows" class="accounts__row">
            <span class="accounts__row-label col-6">
              <div>
                <a
                class="accounts__row-link"
                c11n-link
                href="{{account.isParticipant ? content.participantHomePageUrl : account.url}}"
                variant="secondary-independent"
                [attr.data-testid]="'managed-accounts'"
                size="inherit"
                fontWeight="normal"
                >{{ account.accountLabel }}
                </a>
              </div>
              <span class="mergeExpandFlag accounts__row-merge-flag" *ngIf="account?.accountFlags?.mergeExpandPending">{{
                content.mergeExpandPendingText
              }}</span>
              <p
                class="transferFlag c11n-text-sm d-print-none accounts__row-transfer-flag"
                *ngIf="account?.accountFlags?.transferOfAssetsPending"
              >
                <a
                  c11n-link
                  href="{{ content.trackYourTransferLink }}{{ account.id }}"
                  variant="secondary-independent"
                  iconName="link-right"
                  >{{ content.trackYourTransferText }}</a
                >
              </p>
            </span>
            <div class="accounts__row-balance col-6">{{ account.balance | currency: 'USD' | emDash }}</div>
          </div>
        </div>
      </span>
    </c11n-accordion>
    <c11n-accordion
      *ngIf="selfManagedAccounts.length"
      ngClass="accordion"
      [isExpanded]="true"
      [size]="'large'"
      labelText="{{ content.selfManagedLabel }}"
      summaryText="{{ totals.selfManaged | currency: 'USD' }}"
      [iconPosition]="'leading'"
      [labelWeight]="'heavy'"
      (stateChange)="trackCollapseAdobe('balances_selfManagedAccounts_accordion')"
    >
      <span accordion-body>
        <div *ngIf="selfManagedAccounts.length" class="accounts__wrapper col-12">
          <div *ngFor="let account of selfManagedAccountRows" class="accounts__row">
            <span class="accounts__row-label col-6">
              <div>
                <a
                class="accounts__row-link"
                c11n-link
                href="{{account.isParticipant ? content.participantHomePageUrl : account.url}}"
                variant="secondary-independent"
                size="inherit"
                fontWeight="normal"
                [attr.data-testid]="'self-managed-accounts'"
                >{{ account.accountLabel }}
                </a>
              </div>
              <span
                class="mergeExpandFlag accounts__row-merge-flag"
                *ngIf="account?.accountFlags?.mergeExpandPending"
                accountInfo
              >
                {{ content.mergeExpandPendingText }}
              </span>
              <p
                class="transferFlag c11n-text-sm d-print-none accounts__row-transfer-flag"
                *ngIf="account?.accountFlags?.transferOfAssetsPending"
              >
                <a
                  c11n-link
                  href="{{ content.trackYourTransferLink }}{{ account.id }}"
                  variant="secondary-independent"
                  iconName="link-right"
                  >{{ content.trackYourTransferText }}</a
                >
              </p>
            </span>
            <div class="accounts__row-balance col-6">{{ account.balance | currency: 'USD' | emDash }}</div>
          </div>
        </div>
      </span>
    </c11n-accordion>
    <div class="table-footer-wrapper">
      <ss-lib-table-footer
        [label]="tableFooterLabel"
        [value]="tableFooterValue"
      ></ss-lib-table-footer>
    </div>
    <ng-container *ngIf="privateEquityAccounts?.length">
      <app-private-equity
        [accounts]="privateEquityRows"
        [privateEquityTotal]="totals.privateEquityTotalBalance"
      ></app-private-equity>
    <div class="table-footer-wrapper">
      <ss-lib-table-footer
        [label]="content.totalPeAssetsLabel"
        [value]="totals.privateEquityTotalBalance"
      ></ss-lib-table-footer>
    </div>
    </ng-container>
    <div>
      <table class="outsideInvestments balances-print-table" *ngIf="outsideInvestments.length > 0">
        <tr>
          <td>
            <a
              c11n-link
              [href]="outsideInvestmentsUrl"
              variant="secondary-independent"
              fontWeight="normal"
              >{{ outsideInvestmentsLabel }}</a
            >
          </td>
          <td>
            {{ outsideInvestmentsValue | currency: 'USD' }}
          </td>
        </tr>
      </table>
    </div>
    <table class="table-bottom-links">
      <tr>
        <td>
          <a
            c11n-link
            variant="secondary-independent"
            href="{{ content.balancesByDateUrl }}"
            fontWeight="normal"
            >{{ content.balancesByDateLabel }}</a
          >
        </td>
        <td>
          <a
            c11n-link
            variant="secondary-independent"
            href="{{ content.holdingsSummaryUrl }}"
            fontWeight="normal"
            >{{ content.holdingsSummaryLabel }}</a
          >
        </td>
      </tr>
    </table>
  </div>
</ng-container>
