export enum LoggerCode {
  UNKNOWN_ERROR = 'S000',
  GATEKEEPER_SERVICE_ERROR = 'S001',
  HTTP_CLIENT_ERROR = 'C001',
  APOLLO_CLIENT_ERROR = 'C002',
  GRAPHQL_SERVICE_ERROR = 'C003',
  HTTP_CLIENT_SUCCESS = 'C004',
  INTERACT_ERROR = 'C005', // Obsolete. An error code from obsolete Interact-based MSA service.
  PAGE_LOAD = 'C010',
}
