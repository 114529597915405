import { Injectable } from '@angular/core';
import { AsyncSubject, firstValueFrom } from 'rxjs';
import { AccountsDataGQL, AccountsInfo } from '@generated/graphql';
import { GraphQLService } from '@app/services/graphql/graphql.service';
import { ProductTypeInterpreterService } from '../product-type-interpreter/product-type-interpreter-service';

@Injectable()
export class BalancesService {
  private accountsData$: AsyncSubject<AccountsInfo>;
  private accountsInfo: AccountsInfo;

  constructor(
    private readonly accountsDataGQL: AccountsDataGQL,
    private readonly gqlService: GraphQLService,
    private readonly productTypeInterpreterService: ProductTypeInterpreterService,
  ) {
    this.accountsData$ = new AsyncSubject<AccountsInfo>();
    this.fetchAccountData();
  }

  private fetchAccountData(): void {
    this.gqlService.query(this.accountsDataGQL.document).subscribe(({ data }) => {
      let accountsInfo: AccountsInfo = {
        accounts: [],
        privateEquityInfo: {
          privateEquityAccounts: [],
        },
        totals: {
          managed: 0,
          selfManaged: 0,
          vanguardAssets: 0,
          outsideInvestments: 0,
          totalBalance: 0,
          totalBalanceAsOfDate: undefined,
          privateEquityTotalBalance: 0,
        },
        accountErrors: {
          hasInstitutionalError: false,
        },
      };

      if (data?.accountsInfo) {
        accountsInfo = data.accountsInfo;
      }

      this.accountsData$.next(accountsInfo);
      this.accountsData$.complete();
    });
  }

  /**
   * Returns all account and total balance data
   */
  public async getBalancesData(): Promise<AccountsInfo | undefined> {
    return await firstValueFrom(this.accountsData$, { defaultValue: undefined }).then(
      (data) => data,
    );
  }

  public async getHasCashManagementAccount(): Promise<boolean> {
    return await firstValueFrom(this.accountsData$).then((data) =>
      data.accounts.some((account) => account.isCashManagement),
    );
  }

  public async getHasPrivateEquityAccount(): Promise<boolean> {
    return await firstValueFrom(this.accountsData$).then(
      (data) => !!data.privateEquityInfo?.privateEquityAccounts?.length,
    );
  }

  public async getHasPersonalTrustAccount(): Promise<boolean> {
    return firstValueFrom(this.accountsData$).then((data) => {
      return data.accounts.some((account) =>
        this.productTypeInterpreterService.isPersonalTrust(account.productType),
      );
    });
  }
}
