<c11n-accordion
    [isExpanded]="true"
    [size]="'large'"
    [labelText]="privateEquityHeaderLabel"
    summaryText="{{ privateEquityTotal | currency: 'USD' }}"
    [iconPosition]="'leading'"
    [labelWeight]="'heavy'"
    (stateChange)="
      trackCollapseAdobe('balances_' + privateEquityHeaderLabel + '_accordion')
    "
    ><
    <span accordion-body>
      <table ss-lib-table class="balances-print-table">
        <tbody>
          <ng-container *ngFor="let row of accounts">
            <tr>
              <td>
                <a
                  class="account-link"
                  c11n-link
                  href="{{ row.url }}"
                  variant="secondary-independent"
                  size="inherit"
                  fontWeight="normal"
                  [attr.data-testid]="'private-equity-accounts'"
                  >{{ row.name | emDash }}</a
                >
                <div class="secondary-name-line c11n-text-sm mt-2">
                  {{ row.associatedAccountLabel | emDash }}
                </div>
              </td>
              <td>{{ row.balance | currency: 'USD' | emDash }}{{ '' | dagger: 1 }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </span>
  </c11n-accordion>
