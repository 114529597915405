import { Directive, Input, OnInit } from '@angular/core';
import { content } from '../../../content/content';

@Directive({
  selector: '[content]',
  exportAs: 'bundle',
})
export class ContentDirective implements OnInit {
  @Input() bundleName: string;
  constructor() {}

  ngOnInit(): void {
    if (this.bundleName && content[this.bundleName]) {
      Object.assign(this, content[this.bundleName]);
    }
  }
}
