import { Component, Input, OnInit } from '@angular/core';
import { PrivateEquityAccountRow } from '@models/data-table';
import { AdobeLaunchService } from '@vanguard/secure-site-components-lib';
import { DOMAINS } from '@content/domains.test.external';
import { Router } from '@angular/router';
import { content } from '@content/content';

@Component({
  selector: 'app-private-equity',
  templateUrl: './private-equity.component.html',
  styleUrls: ['./private-equity.component.scss'],
})
export class PrivateEquityComponent implements OnInit {
  @Input() privateEquityTotal: number;
  @Input() accounts: PrivateEquityAccountRow[];
  currentURL: string;
  privateEquityHeaderLabel = content.balancesTable.privateEquityHeaderLabel;

  constructor(private adobeLaunchService: AdobeLaunchService, private router: Router) {}

  ngOnInit(): void {
    this.currentURL = DOMAINS.BALANCES + this.router.url;
  }

  trackCollapseAdobe(profileType) {
    this.adobeLaunchService.trackEvent({
      location: this.currentURL,
      ctaName: profileType,
      ctaType: 'Accordion',
    });
  }
}
