import { Component, Input } from '@angular/core';
import { content } from '@content/content';
import {
  NavOverflowData,
  NavOverflowGroup,
  NavOverflowItem,
  NavOverflowModule,
} from '@vg-constellation/angular-14/nav-overflow';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [NavOverflowModule, CommonModule],
  selector: 'app-transact-overflow',
  templateUrl: './transact.component.html',
})
export class TransactComponent {
  //TODO: Utilize type defined in SSCRPG-3549
  @Input() alternateTradePaths: { etfsAtp: boolean; mutualFundsAtp: boolean } = {
    etfsAtp: false,
    mutualFundsAtp: false,
  };
  menuData: NavOverflowData;

  ngOnInit(): void {
    this.populateTransactMenu();
  }
  private populateTransactMenu(): void {
    const alternateTradePathMenuItemGroup: NavOverflowGroup[] = this.getMenuItemGroup(
      this.generateAlternateTradePathMenuItems(),
    );
    this.menuData = this.generateTransactMenuData(alternateTradePathMenuItemGroup);
  }
  private generateAlternateTradePathMenuItems(): NavOverflowItem[] {
    const alternateTradePathMenuItems: NavOverflowItem[] = [];
    if (this.alternateTradePaths.etfsAtp) {
      alternateTradePathMenuItems.push(
        this.getMenuItem(
          content.transactContent.etfsAlternateTradePath.labelText,
          content.transactContent.etfsAlternateTradePath.href,
        ),
      );
    }
    if (this.alternateTradePaths.mutualFundsAtp) {
      alternateTradePathMenuItems.push(
        this.getMenuItem(
          content.transactContent.buyMutualFundsAlternateTradePath.labelText,
          content.transactContent.buyMutualFundsAlternateTradePath.href,
        ),
      );
      alternateTradePathMenuItems.push(
        this.getMenuItem(
          content.transactContent.sellMutualFundsAlternateTradePath.labelText,
          content.transactContent.sellMutualFundsAlternateTradePath.href,
        ),
      );
    }
    return alternateTradePathMenuItems;
  }
  private generateTransactMenuData(menuItemGroups: NavOverflowGroup[]): NavOverflowData {
    return { groups: menuItemGroups };
  }
  private getMenuItemGroup(menuItems: NavOverflowItem[]): NavOverflowGroup[] {
    return [{ items: menuItems }];
  }
  private getMenuItem(labelText: string, href: string): NavOverflowItem {
    return {
      labelText,
      href,
    };
  }
}
