import { Injectable } from '@angular/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
  ApolloLink,
  ApolloQueryResult,
  from,
  InMemoryCache,
  QueryOptions,
} from '@apollo/client/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { setContext } from '@apollo/client/link/context';
import { DocumentNode } from 'graphql';
import { CookieService } from 'ngx-cookie-service';
import { onError } from '@apollo/client/link/error';
import { LoggerCode } from '@models/logger';
import { catchError, take, timeout } from 'rxjs/operators';
import { LoggerService } from '@vanguard/secure-site-components-lib';

@Injectable({
  providedIn: 'any',
})
export class GraphQLService {
  constructor(
    private loggerService: LoggerService,
    private apollo: Apollo,
    private httpLink: HttpLink,
    private cookie: CookieService,
  ) {
    const basic = setContext((operation, context) => ({
      headers: {
        Accept: 'application/json',
      },
    }));

    const auth = setContext((operation, context) => ({
      headers: {
        'X-XSRF-TOKEN': this.cookie.get('XSRF-TOKEN'),
      },
    }));

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ originalError, message }) =>
          this.loggerService.error({
            feature: 'ApolloService',
            url: environment.GRAPHQL_URI,
            message: message,
            logCode: LoggerCode.APOLLO_CLIENT_ERROR,
            error: originalError || message,
          }),
        );
      }

      if (networkError) {
        this.loggerService.error({
          feature: 'ApolloService',
          message: networkError.message,
          logCode: LoggerCode.HTTP_CLIENT_ERROR,
          error: networkError,
        });
      }
    });

    const apolloLink = ApolloLink.from([
      basic,
      auth,
      this.httpLink.create({
        uri: environment.GRAPHQL_URI,
        withCredentials: true,
      }),
    ]);

    this.apollo.create({
      ...APOLLO_OPTIONS,
      name: 'balances.frontend',
      cache: new InMemoryCache(),
      link: from([errorLink, apolloLink]),
    });
  }

  /**
   * Makes a GraphQL query using Apollo Client's query function
   * If there are errors (it may still return a 200 OK), log it and reject the Promise
   * If no errors, extract actual data under the "data" object and resolves
   * @param query Stringified GraphQL Query
   */
  query<T = any>(query: DocumentNode, noCache?: boolean): Observable<ApolloQueryResult<T>> {
    const fetchPolicyString = noCache ? 'no-cache' : 'cache-first';

    const queryObject: QueryOptions = {
      query: query,
      errorPolicy: 'all',
      fetchPolicy: fetchPolicyString,
    };

    return this.apollo.query(queryObject).pipe(
      timeout(environment.GRAPHQL_TIMEOUT),
      catchError((error) => {
        this.loggerService.error({
          feature: 'GraphQLService',
          message: error.message,
          logCode: LoggerCode.GRAPHQL_SERVICE_ERROR,
          error: error,
        });

        return of({ data: null, errors: [error] } as any);
      }),
      take(1),
    );
  }
}
